const select = (langKey) => {
  var res;
  switch (langKey) {
    case "en":
      res = "en";
      break;
    case "zh":
      res = "zh";
      break;
    default:
      res = null;
  }
  return res;
};

export default select;
