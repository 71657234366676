import React from "react";
import PropTypes from "prop-types";
import Link from "gatsby-link";
import { Button, Typography, Grid } from '@mui/material';


const SelectLanguage = (props) => {
  const languageTag = {
    en: "EN - English",
    zh: "ZH_CN - 简体中文",
  }
  const linksOf = props.langs.map((lang, index) => {
    return (
      <Grid item key={index}
        selected={lang.selected}
      >
        <Typography>
          <Link
            to={lang.link}
            alt={lang.langKey}
          >
            {lang.selected ? <Button size="small" disabled>
              {languageTag[`${lang.langKey}`]}
            </Button> : <Button size="small" color="primary">
              {languageTag[`${lang.langKey}`]}
            </Button>}
          </Link>
        </Typography>
      </Grid>
    );
  });
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={1}
    >
      {linksOf}
    </Grid>
  );
};

SelectLanguage.propTypes = {
  langs: PropTypes.array,
};

export default SelectLanguage;
