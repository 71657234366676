import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "../../components/AccessibleLink";
import Box from "@mui/material/Box";
import SelectLanguage from "../../utils/SelectLanguage";
import select from "../../utils/select";
import EmailListForm from "../../components/EmailListForm";
import { FormattedMessage } from "react-intl";
import { styled } from "@mui/material/styles";

interface FooterLabelsobject {
  termsAndConditionsLabelFooter: string;
  privacyNoticeLabelFooter: string;
  TermOfUse: string;
  SocialMedia: string;
  LinkedIn: string;
  Facebook: string;
  Explore: string;
  Manifesto: string;
  ContactMe: string;
}

interface FooterProps {
  footerLabels?: FooterLabelsobject;
}

export default function Footer(props) {
  const NewContainer = styled("div")(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,
    maxWidth: "100%",
    paddingRight: "4%",
    paddingLeft: "7%",
    display: "block",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  }));

  const {
    termsAndConditionsLabelFooter,
    privacyNoticeLabelFooter,
    TermOfUse,
    SocialMedia,
    LinkedIn,
    Facebook,
    Explore,
    Manifesto,
    ContactMe,
    langs,
    langKey,
  } = props;

  const sel = select(props.langKey);

  return (
    <>
      {/* Footer */}
      <NewContainer>
        <Grid container spacing={4} justifyContent='space-evenly'>
          <Grid
            item
            xs={12}
            md={6}
            container
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            spacing={2}
          >
            <Grid item>
              <Typography
                sx={{ mb: 2 }}
                variant='subtitle2'
                color='text.primary'
              >
                {termsAndConditionsLabelFooter}
              </Typography>
              <Typography
                sx={{ mt: 1 }}
                variant='subtitle2'
                color='text.primary'
              >
                <Link to='/privacy'>{privacyNoticeLabelFooter}</Link>
              </Typography>
              <Typography
                sx={{ mt: 2 }}
                variant='subtitle2'
                color='text.primary'
              >
                <Link to='/terms'>{TermOfUse}</Link>
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                pr: 4,
              }}
            >
              <Typography
                sx={{ mb: 2 }}
                variant='subtitle2'
                color='text.primary'
              >
                {SocialMedia}
              </Typography>
              <Typography
                sx={{ mt: 1 }}
                variant='subtitle2'
                color='text.secondary'
              >
                {/* <Link to='#'>LinkedIn</Link> */}
                {LinkedIn}
              </Typography>

              <Typography
                sx={{ mt: 2 }}
                variant='subtitle2'
                color='text.secondary'
              >
                {/* <Link to='#'>Facebook</Link> */}
                {Facebook}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                pr: 4,
              }}
            >
              <Typography
                sx={{ mb: 2 }}
                variant='subtitle2'
                color='text.primary'
              >
                {Explore}
              </Typography>
              <Typography
                sx={{ mt: 1 }}
                variant='subtitle2'
                color='text.primary'
              >
                <Link to='/about/manifesto'>{Manifesto}</Link>
              </Typography>

              <Typography
                sx={{ mt: 2 }}
                variant='subtitle2'
                color='text.primary'
              >
                <Link to='/contact'>{ContactMe}</Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <EmailListForm />
          </Grid>
        </Grid>
        <Box mt={5}>
          <SelectLanguage langs={props.langs} />
        </Box>
      </NewContainer>
      {/* End footer */}
    </>
  );
}
