import * as Yup from "yup";
import { Formik } from "formik";
import {
  Card,
  Grid,
  Link,
  TextField,
  FormHelperText,
  Typography,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import TransitionAlerts from "./TransitionAlerts";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { FormattedMessage } from "react-intl";

const wait = (time) => new Promise((res) => setTimeout(res, time));

interface EmailListFormProps {
  lang?: string;
  emailIsInvalidWarning?: string | any;
  emailIsRequiredWarning?: string | any;
}

const EmailListForm: React.FC<EmailListFormProps> = ({
  lang,
  emailIsInvalidWarning = <FormattedMessage id='emailIsInvalidWarning' />,
  emailIsRequiredWarning = <FormattedMessage id='emailIsRequiredWarning' />,
}: EmailListFormProps) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Formik
      initialValues={{
        email: "",
        message: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(emailIsInvalidWarning)
          .max(255)
          .required(emailIsRequiredWarning),
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          // NOTE: Make API request
          const theUrl = `https://katjas.azurewebsites.net/api/newsletter`;
          let params = {};
          params["email"] = values.email;
          const regData = JSON.stringify(params);
          const response = await fetch(theUrl, {
            method: "POST",
            body: regData,
          });
          const data = await response.json();
          await wait(500);
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          setOpen(true);
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <Box sx={{ p: 3 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      mb: 3,
                    }}
                    variant='subtitle1'
                    color='primary'
                  >
                    <FormattedMessage id='ctaTitleLabelForm' />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label={<FormattedMessage id='emailLabelForm' />}
                    name='email'
                    InputLabelProps={{ shrink: true }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    size='small'
                    value={values.email}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TransitionAlerts
                    state={[open, setOpen]}
                    message={<FormattedMessage id='AfterSubscribedMessage' />}
                  />
                  <Collapse in={!open}>
                    <FormHelperText>
                      <FormattedMessage id='ctaDescriptionLabelForm' />
                      <Link href={`/${lang}/legal/terms`}>
                        <FormattedMessage id='catLinkLabelForm4' />
                      </Link>
                    </FormHelperText>
                  </Collapse>
                </Grid>
              </Grid>
              <Box sx={{ mt: 2 }}>
                <Button
                  color='primary'
                  disabled={isSubmitting}
                  type='submit'
                  variant='contained'
                >
                  <FormattedMessage id='subscribeLabelForm' />
                </Button>
              </Box>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default EmailListForm;
