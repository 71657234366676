import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import PostList from "../components/homepage/PostList";
import { FormattedMessage } from "react-intl";
import Helmet from "react-helmet";
import Badge from '@mui/material/Badge';
import { Box, Typography, Chip } from '@mui/material';
import LabelRoundedIcon from '@mui/icons-material/LabelRounded';

const TagRouteTemplate = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges.map((p) => p.node);

  return (
    <Box sx={{
      pt: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexWrap: 'wrap',
      backgroundColor: "background.paper",
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}>
        <FormattedMessage id='tags'>
          {(txt) => (
            <Helmet
              title={`${pageContext.tag} | ${txt}`}
              meta={[{ name: "description", content: txt }]}
            />
          )}
        </FormattedMessage>
        <Badge badgeContent={data.allMarkdownRemark.totalCount} color="primary">
          <Typography variant="h3" color="primary">{pageContext.tag}</Typography>
        </Badge>
      </Box>
      <Box spacing={2}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'start',
          flexWrap: 'wrap',
          m: 2,
        }}>
        <PostList posts={posts} />
      </Box>
      <Box>
        <Typography variant="caption" color="initial">
          <FormattedMessage id='Tags.AllTagsLink'>
            {(txt, index) => (
              <Link to={`/${pageContext.langKey}/tags/`}>
                <Chip key={Math.random + index} sx={{
                  m: 1,
                  color: "#fff"
                }} size="small" icon={<LabelRoundedIcon />} label={<Typography variant="body2" color="textPrimary">{txt}</Typography>} clickable />
              </Link>
            )}

          </FormattedMessage>
        </Typography>
      </Box>
    </Box>
  );
};

TagRouteTemplate.propTypes = {
  posts: PropTypes.object,
  pageContext: PropTypes.object,
};

export default TagRouteTemplate;
